<template>
  <div class="container-background">
    <div class="display-flex align-items-center justify-content-center">
      <div
        @click="home"
        class="content-image cursor-pointer"
      >
        <img
          src="@/assets/icons/logo.png"
          alt=""
        >
      </div>
    </div>
    <div class="display-flex align-items-center justify-content-center mt-5 mb-5">
      <p class="text-title mon-bold">{{ texts.login.textTitle }}</p>
    </div>
    <div>
      <v-text-field
        v-model="email"
        type="email"
        :label="texts.login.email"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular"
        :placeholder="texts.login.email"
        persistent-placeholder
        maxlength="85"
        @keypress="preLogin"
      ></v-text-field>
      <v-text-field
        v-model="password"
        name="password"
        :label="texts.login.password"
        :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (bShowPassword = !bShowPassword)"
        :type="bShowPassword ? 'text' : 'password'"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular mt-5"
        :placeholder="texts.login.password"
        persistent-placeholder
        autocomplete="new-password"
        @keypress="preLogin"
      >
      </v-text-field>
    </div>
    <div class="mt-7">
      <v-btn
        @click="login"
        :loading="bLoading"
        elevation="0"
        class="button-login mon-bold"
      >
        {{ texts.login.buttonLogin }}
      </v-btn>
    </div>
    <div class="mt-5 display-flex align-items-center justify-content-center">
      <v-btn
        @click="passwordRecovery"
        elevation="0"
        text
        class="button-forgot mon-regular"
      >
        {{ texts.login.buttonForgot }}
      </v-btn>
    </div>
    <div class="mt-5">
      <v-btn
        @click="signup"
        elevation="0"
        class="button-signup mon-regular"
      >
        {{ texts.login.buttonSignup }} <label class="mon-bold ml-1">{{ texts.login.textProplat }}</label>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginFormLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      email: "",
      password: "",
      bShowPassword: false,
      bLoading: false,
    };
  },
  beforeMount() {},
  methods: {
    preLogin: function ($event) {
      if ($event.code == "Enter") this.login();
    },
    login: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/auth/vendor`, payload)
        .then((response) => {
          this.bLoading = false;

          this.$store.commit("setToken", response.data.sToken);
          // this.$store.commit("setEmail", response.data.sEmail);
          this.$store.commit("setFullName", response.data.sFullname);
          this.$store.commit("setEnterpriseId", response.data.sEnterpriseId);
          this.$store.commit("setUserId", response.data.sUserId);
          this.$store.commit("setIsLogged", true);
          this.$store.commit("setManager", response.data.bManager);
          this.$store.commit("setVerified", response.data.bVerifiedEnterprise);

          this.mixInfo(response.data.sMessage);
          this.$router.push("/home").catch(() => {});
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    home: function () {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
    signup: function () {
      this.$router
        .push({
          name: "Signup",
        })
        .catch(() => {});
    },
    passwordRecovery: function () {
      this.$router
        .push({
          name: "PasswordRecovery",
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {},
};
</script>

<style scoped>
.button-signup {
  background-color: #ffffff !important;
  height: 40px !important;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  font-size: 14px !important;
  text-transform: initial;
}

.button-forgot {
  color: #0049b7 !important;
  opacity: 1;
  text-transform: initial;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
}

.button-forgot:hover {
  text-decoration: underline;
}

.button-login {
  width: 100%;
  height: 40px !important;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-title {
  text-align: center;
  font-size: 33px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-background {
  width: 100%;
}

.content-image {
  width: 180px;
  height: 130px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

@media (max-width: 600px) {
  .text-title {
    font-size: 25px;
  }
}
</style>